<template>
  <div
    class="inProcess"
    :style="{
      height:
        neet == 1
          ? '900px'
          : neet == 2
          ? '940px'
          : neet == 3
          ? '1100px'
          : neet == 4
          ? '1680px'
          : neet == 5
          ? '800px'
          : '',
    }"
  >
    <div class="bg"></div>
    <div class="main-card">
      <BreadCrumbs></BreadCrumbs>
      <div class="stpes">
        <div class="shopee-step active">
          <div class="shopee-step__head">1</div>
          <div class="shopee-step__test">法人实名认证</div>
          <div class="shopee-step__tail"></div>
        </div>
        <div class="shopee-step" :class="{ active: neet > 1 }">
          <div class="shopee-step__head">2</div>
          <div class="shopee-step__test">基本信息</div>
          <div class="shopee-step__tail"></div>
        </div>
        <div class="shopee-step" :class="{ active: neet > 2 }">
          <div class="shopee-step__head">3</div>
          <div class="shopee-step__test">公司信息</div>
          <div class="shopee-step__tail"></div>
        </div>
        <div class="shopee-step" :class="{ active: neet > 3 }">
          <div class="shopee-step__head">4</div>
          <div class="shopee-step__test">店铺信息</div>
          <div class="shopee-step__tail"></div>
        </div>
        <div class="shopee-step" :class="{ active: neet > 4 }">
          <div class="shopee-step__head">5</div>
          <div class="shopee-step__test">完成</div>
        </div>
      </div>
      <div style="background: #fff">
        <div class="content" v-if="neet == 1">
          <el-form
            ref="form1"
            :rules="rules1"
            :model="form.legal"
            label-width="200px"
          >
            <el-form-item
              prop="id_card_url_front"
              label="法人身份证（正面）照片"
            >
              <UploadFiles
                :ids="form.legal.id_card_front"
                :imageUrl="form.legal.id_card_url_front"
                :states="1"
                ref="tp1"
              >
              </UploadFiles>
            </el-form-item>
            <el-form-item
              prop="id_card_url_back"
              label="法人身份证（背面）照片"
            >
              <UploadFiles
                :ids="form.legal.id_card_back"
                :imageUrl="form.legal.id_card_url_back"
                :states="1"
                ref="tp2"
              >
              </UploadFiles
            ></el-form-item>
            <el-form-item>
              <p>
                1.上传图片大小不得大于7M；
                <br />
                2.建议卡片部分占据图片2/3以上；
                <br />
                3.实名认证目前仅支持中国大陆身份证，如您的身份证为其他形式，请联系Shopee客服协助；
                <br />
                4.所上传证件需在证件有效期内；
                <br />
                5.请确认提交的法人身份证件清晰有效，否则将影响审核；
              </p>
            </el-form-item>
            <el-form-item prop="realname" label="真实姓名">
              <el-input
                v-model="form.legal.realname"
                placeholder="与法人身份证姓名一致"
              ></el-input>
            </el-form-item>
            <el-form-item prop="number" label="身份证号码" style="margin: 0">
              <el-input
                v-model="form.legal.number"
                placeholder="与法人身份证号码一致"
              ></el-input>
              <p>请仔细核对法人身份信息，如有误请修改；</p>
            </el-form-item>
          </el-form>
        </div>
        <div class="content" v-if="neet == 2">
          <el-form
            ref="form2"
            :rules="rules2"
            :model="form.basic"
            label-width="200px"
          >
            <el-form-item
              :show-message="sm"
              prop="contact_name"
              label="联系人姓名"
            >
              <el-input v-model="form.basic.contact_name"></el-input>
            </el-form-item>
            <el-form-item
              :show-message="sm"
              prop="contact_position"
              label="联系人职位"
            >
              <el-select
                v-model="form.basic.contact_position"
                placeholder="请选择联系人职位"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, key, index) in options.nature"
                  :label="item"
                  :key="index"
                  :value="key"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              :show-message="sm"
              prop="company_email"
              label="公司邮箱"
            >
              <el-input v-model="form.basic.company_email"></el-input>
              <p>
                Shopee所有通知都会发在该邮箱，请保证邮箱正确且经常使用(建议为QQ邮箱)
              </p>
            </el-form-item>
            <el-form-item
              :show-message="sm"
              prop="contact_mobile"
              label="联系人手机号"
              style="margin: 0"
            >
              <div style="display: flex">
                <el-input
                  placeholder="请输入内容"
                  v-model="form.basic.contact_mobile"
                  class="input-with-select"
                  oninput="value=value.replace(/[^\d]/g,'')"
                >
                  <el-select
                    style="width: 80px"
                    v-model="num"
                    slot="prepend"
                    placeholder="请选择"
                  >
                    <el-option label="+86" value="+86"></el-option>
                    <el-option label="+886" value="+52"></el-option>
                  </el-select>
                </el-input>
                <el-button
                  :disabled="
                    form.basic.contact_mobile.length < 7 ||
                    form.basic.contact_mobile.length < 11
                  "
                  style="margin-left: 10px"
                  size="small"
                  @click="open"
                  >发送验证码</el-button
                >
              </div>

              <p>
                请填写真实有效的手机号码，且务必保证手机号可以联系且可以接收验证码，请确保手机号未加入Shopee企业微信，此号码在开店成功后将用于平台企业微信及客户经理对接
              </p>
            </el-form-item>
            <el-form-item
              :show-message="sm"
              prop="manage_type"
              label="过往主要经营经验"
              style="margin-top: 10px"
            >
              <el-radio-group v-model="form.basic.manage_type">
                <div style="padding: 10px 0">
                  <el-radio
                    :label="key"
                    v-for="(item, key, index) in options.business_experience"
                    :key="index"
                    style="padding: 8px 0"
                    >{{ item }}</el-radio
                  >
                </div>
              </el-radio-group>
              <p style="margin-top: 10px">
                选择后无法更改，请务必根据自身真实情况谨慎选择！该选择将决定后续审核材料类型及运营管理团队分配
                <br />
                -跨境电商：具备跨境电商经验，如亚马逊、eBay、阿里国际站等
                <br />
                -内贸电商：具备国内电商经验，如淘宝、京东、拼多多、1688等
                <br />
                -传统外贸：B2B接国际订单 -传统内贸：B2B接国内订单
                <br />
                -无经验：没有任何经验
              </p>
            </el-form-item>
          </el-form>
        </div>
        <div class="content" v-if="neet == 3">
          <el-form
            ref="form3"
            :rules="rules3"
            :model="form.company"
            label-width="200px"
          >
            <el-form-item
              :show-message="sm"
              prop="company_license"
              label="营业执照原件(正副本)照片"
            >
              <UploadFiles
                :ids="form.company.company_license"
                :imageUrl="form.company.company_licenseIMG"
                :states="2"
                ref="tp3"
              >
              </UploadFiles>
              <p>
                只支持上传JPG,PNG,JPEG格式图片；
                <br />
                上传图片不得大于3M；
                <br />
                可提供正本原件照片或副本原件照片，但需要与后续所提交视频中的营业执照保持一致；
              </p>
            </el-form-item>
            <el-form-item
              :show-message="sm"
              prop="company_name"
              label="营业执照公司名称"
            >
              <el-input
                v-model="form.company.company_name"
                show-word-limit
                maxlength="70"
              ></el-input>
            </el-form-item>
            <el-form-item
              :show-message="sm"
              prop="company_credit_code"
              label="营业执照统一企业信用代码"
            >
              <el-input
                v-model="form.company.company_credit_code"
                placeholder="请输入统一企业信用代码"
              ></el-input>
              <p>
                企业信用代码必须与营业执照(正本)完全一致，注意区分字母和数字，如：字母O和数字0
              </p>
            </el-form-item>
            <el-form-item
              :show-message="sm"
              label="全年流水金额"
              prop="company_income"
            >
              <el-select
                v-model="form.company.company_income"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  :label="item"
                  v-for="(item, key, index) in options.company_flow"
                  :key="index"
                  :value="key"
                ></el-option>
              </el-select>
              <p>请选择申请公司的全年流水金额（单位：人民币）</p>
            </el-form-item>
            <el-form-item
              :show-message="sm"
              label="公司总人数"
              prop="company_total"
            >
              <el-select
                v-model="form.company.company_total"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  :label="item"
                  v-for="(item, key, index) in options.company_total"
                  :key="index"
                  :value="key"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              :show-message="sm"
              label="是否有工厂"
              prop="company_is_factory"
            >
              <el-radio-group v-model="form.company.company_is_factory">
                <el-radio :label="1">自有工厂</el-radio>
                <el-radio :label="0">无工厂</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              :show-message="sm"
              label="办公地址"
              prop="company_address"
            >
              <div style="display: flex; justify-content: space-around">
                <div class="china">中国</div>
                <el-select
                  v-model="form.company.company_province_code"
                  @change="selectedSwitch"
                  placeholder="省"
                  style="width: 24%"
                >
                  <el-option
                    v-for="(item, key, index) in areaList.province_list"
                    :key="index"
                    :label="item"
                    :value="key"
                  ></el-option>
                </el-select>
                <el-select
                  v-model="form.company.company_city_code"
                  placeholder="市"
                  style="width: 24%"
                  @change="selectedSwitch2"
                >
                  <el-option
                    v-for="(item, key, index) in city_list"
                    :key="index"
                    :label="item"
                    :value="key"
                  ></el-option>
                </el-select>
                <el-select
                  v-model="form.company.company_area_code"
                  placeholder="区"
                  style="width: 24%"
                  @change="selectedSwitch3"
                >
                  <el-option
                    v-for="(item, key, index) in county_list"
                    :key="index"
                    :label="item"
                    :value="key"
                  ></el-option>
                </el-select>
              </div>

              <el-input
                v-model="form.company.company_address"
                placeholder="请输入详细地址"
                type="textarea"
              ></el-input>
              <p>办公地址可以是实际运营场所地址，或者营业执照上的地址</p>
            </el-form-item>
          </el-form>
        </div>
        <div class="content" v-if="neet == 4">
          <el-form
            ref="form4"
            :rules="rules4"
            :model="form.shop"
            label-width="200px"
          >
            <el-form-item
              :show-message="sm"
              label="主要品类"
              prop="shop_main_cate"
            >
              <el-select
                v-model="form.shop.shop_main_cate"
                placeholder="请选择主要品类"
                style="width: 100%"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="(item, index) in options.category"
                  :key="index"
                ></el-option>
              </el-select>
              <p>请填写您即将在Shopee经营的品类</p>
            </el-form-item>
            <el-form-item
              :show-message="sm"
              label="次要品类"
              prop="shop_sub_cate"
            >
              <el-select
                v-model="form.shop.shop_sub_cate"
                placeholder="请选择次要品类"
                style="width: 100%"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="(item, index) in options.category"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :show-message="sm"
              label="平均商品单价"
              prop="shop_goods_price"
            >
              <el-select
                v-model="form.shop.shop_goods_price"
                placeholder="请选择单价"
                style="width: 100%"
              >
                <el-option
                  :label="item"
                  :value="Number(key)"
                  v-for="(item, key, index) in options.shop_goods_price"
                  :key="index"
                ></el-option>
              </el-select>
              <p>请填写您即将在Shopee售卖产品的平均商品单价（单位：人民币）</p>
            </el-form-item>
            <el-form-item
              :show-message="sm"
              label="计划在Shopee上架产品数量"
              prop="shop_goods_num"
            >
              <el-select
                v-model="form.shop.shop_goods_num"
                placeholder="请选择数量"
                style="width: 100%"
              >
                <el-option
                  :label="item"
                  :value="Number(key)"
                  v-for="(item, key, index) in options.shop_goods_num"
                  :key="index"
                ></el-option>
              </el-select>
              <p>
                请选择您即将在Shopee上架的产品数量：
                <br />
                即将上架多少个产品，例如不用色/大小的同款衬衣只算一个产品
              </p>
            </el-form-item>
            <el-form-item
              :show-message="sm"
              label="预计在Shopee投入前期资金"
              prop="shop_throw_money"
            >
              <el-select
                v-model="form.shop.shop_throw_money"
                placeholder="请选择金额"
                style="width: 100%"
              >
                <el-option
                  :label="item"
                  :value="Number(key)"
                  v-for="(item, key, index) in options.shop_invest_funds"
                  :key="index"
                ></el-option>
              </el-select>
              <p>
                请选择您即将在Shopee网点投入的前期资金总额（单位：人民币）
                <br />
                包括：贷款、营销、工资、仓库、办公地点和设备等
              </p>
            </el-form-item>
            <el-form-item
              :show-message="sm"
              label="过往从业经验"
              prop="shop_work_experience"
            >
              <el-select
                v-model="form.shop.shop_work_experience"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  :label="item"
                  :value="Number(key)"
                  v-for="(item, key, index) in options.shop_working_experience"
                  :key="index"
                ></el-option>
              </el-select>
              <p>请选择您在从事Shopee前的从业经验</p>
            </el-form-item>

            <el-form-item
              :show-message="sm"
              label="主要货源渠道"
              prop="shop_supply_source"
            >
              <el-select
                v-model="form.shop.shop_supply_source"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  :label="item"
                  :value="Number(key)"
                  v-for="(item, key, index) in options.shop_supply_channel"
                  :key="index"
                ></el-option>
              </el-select>
              <p>请在此处选择您即将开设店铺的核心供应链类型</p>
            </el-form-item>
            <el-form-item
              :show-message="sm"
              label="主要发货模式"
              prop="shop_ship_mode"
            >
              <el-select
                v-model="form.shop.shop_ship_mode"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option label="提前备货" :value="0"></el-option>
                <el-option
                  label="收到订单后进行采购或者生产备货"
                  :value="1"
                ></el-option>
              </el-select>
              <p>请在此处选择您的主要发货模式</p>
            </el-form-item>
            <el-form-item
              :show-message="sm"
              label="退货地址"
              prop="shop_address"
            >
              <div style="display: flex; justify-content: space-around">
                <div class="china">中国</div>
                <el-select
                  v-model="form.shop.shop_province_code"
                  placeholder="省"
                  style="width: 24%"
                  @change="selectedSwitch4"
                >
                  <el-option
                    v-for="(item, key, index) in areaList.province_list"
                    :key="index"
                    :label="item"
                    :value="key"
                  ></el-option>
                </el-select>
                <el-select
                  v-model="form.shop.shop_city_code"
                  placeholder="市"
                  style="width: 24%"
                  @change="selectedSwitch5"
                >
                  <el-option
                    v-for="(item, key, index) in city_lists"
                    :key="index"
                    :label="item"
                    :value="key"
                  ></el-option>
                </el-select>
                <el-select
                  v-model="form.shop.shop_area_code"
                  placeholder="区"
                  style="width: 24%"
                  @change="selectedSwitch6"
                >
                  <el-option
                    v-for="(item, key, index) in county_lists"
                    :key="index"
                    :label="item"
                    :value="key"
                  ></el-option>
                </el-select>
              </div>
              <el-input
                v-model="form.shop.shop_address"
                placeholder="请输入详细地址"
                type="textarea"
              ></el-input>
              <p>办公地址可以是实际运营场所地址，或者营业执照上的地址</p>
            </el-form-item>
            <el-form-item :show-message="sm" label="是否为品牌" prop="is_brand">
              <el-radio-group v-model="form.shop.is_brand">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="登录店铺后台视频">
              <UploadFiles
                :imageUrl="form.shop.shop_video_cover"
                :video="form.shop.shop_video_data"
                :ids="form.shop.shop_video"
                accept=".mp4,.avi"
                ref="tp4"
              >
              </UploadFiles>
              <p>
                ·您可以直接提供后台登录视频提高入驻成功率；
                <br />
                ·支持mp4和avi格式；
                <br />
                ·视频最大支持40M，约20秒左右；
                <br />
                ·如果审核人员没有要求提交该项，请您忽略；
              </p>
            </el-form-item>
            <el-form-item label="其他视频验证">
              <UploadFiles
                :imageUrl="form.shop.shop_other_video_cover"
                :video="form.shop.shop_other_video_data"
                :ids="form.shop.shop_other_video"
                accept=".mp4,.avi"
                ref="tp5"
              >
              </UploadFiles>
              <p>
                ·首次递交可以提供法人手持身份证+三天以内日期的视频提高审核通过率；
                <br />
                ·支持mp4和avi格式；视频最大支持40M，约20秒左右；
              </p>
            </el-form-item>
          </el-form>
        </div>
        <div class="success" v-if="neet == 5">
          <i
            class="el-icon-document-checked"
            style="font-size: 40px; color: green"
          ></i>
          <span style="font-size: 24px">提交成功</span>
          <p>
            1.
            工作人员会在5个工作日内给到回复，请您及时登录查看您的审核进度，如果材料被退回，请根据提示及时修改/补交审核材料。
            <br /><br />
            2.
            您现在可以选择申请开店，用于提前体验店铺操作流程，待审核通过后，店铺销售权会自动激活，即可正常营业。
            <br /><br />
            3. 您也可以选择等待审核通过后，直接开通有销售权的店铺。
          </p>
          <div
            style="
              display: flex;
              justify-content: space-around;
              margin-top: 30px;
            "
          >
            <el-button type="primary" @click="toPush(1)">申请开店</el-button>
            <el-button @click="toPush(2)">查看入驻记录</el-button>
          </div>
        </div>
        <div ref="fdbz"></div>
        <div
          :class="{
            'content-footer2': scrollType,
            'content-footer': !scrollType,
          }"
          id="fd-footer"
          v-if="neet != 5"
          ref="footer"
        >
          <div>
            <el-button @click="$router.back(-1)">退出</el-button>
          </div>
          <div>
            <el-button v-if="neet != 1" @click="toNEXT(0)">保存草稿</el-button>
            <el-button v-if="neet == 1" @click="toJump">跳过</el-button>
            <el-button @click="toPrev" v-if="neet != 1">上一步</el-button>
            <el-button @click="toNEXT(1)" type="primary">{{
              neet == 1 ? "认证完成，下一步" : neet == 4 ? "提交" : "下一步"
            }}</el-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="请输入验证码"
      :visible.sync="dialogTableVisible"
      width="400px"
      style="padding: 120px"
      @close="close"
    >
      <div style="margin-top: -20px">
        <p>
          验证码已发送至{{ num
          }}{{
            form.basic.contact_mobile.slice(0, 3) +
            "****" +
            form.basic.contact_mobile.slice(7, 11)
          }}
        </p>
        <el-input placeholder="请输入内容" v-model="form.basic.sms_code">
          <template slot="append">
            <el-button :disabled="codeType" @click="getCode">{{
              codeText
            }}</el-button>
          </template>
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="medium">取 消</el-button>
        <el-button
          type="primary"
          :disabled="form.basic.sms_code.length < 6"
          size="medium"
          @click="close(1)"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import { getSmsCode } from "@/api/common.js";
import UploadFiles from "@/components/UploadFiles";
import areaList from "@/assets/js/areaList.js";
import {
  legalAuth,
  inList,
  baseInfo,
  companyInfo,
  shopInfo,
} from "@/api/main.js";
import {
  configList,
  //getSmsCode
  getMainCategory,
} from "@/api/common.js";
export default {
  components: {
    BreadCrumbs,
    UploadFiles,
  },
  data() {
    return {
      areaList: areaList,
      city_list: {},
      county_list: {},
      city_lists: {},
      county_lists: {},
      scrollType: true,
      sm: true,
      neet: 1,
      num: "+86",
      dialogTableVisible: false,
      codeText: "重新获取",
      codeNum: 60,
      codeType: false,
      codeTime: null,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        legal: {
          realname: "", //法人姓名
          id_card_url_back: [], //身份证背面面
          id_card_url_front: [], //身份证正面
          id_card_front: [],
          id_card_back: [],
          number: "", //身份证码
        },
        basic: {
          contact_name: "",
          contact_position: "",
          company_email: "",
          contact_mobile: "",
          manage_type: "",
          sms_code: "",
        },
        company: {
          company_license: [],
          company_licenseIMG: [],
          company_name: "",
          company_credit_code: "",
          company_income: "",
          company_total: "",
          company_is_factory: "",
          company_country: "中国",
          company_province: "",
          company_city: "",
          company_area: "",
          company_province_code: "",
          company_city_code: "",
          company_area_code: "",
          company_address: "",
        },
        shop: {
          shop_main_cate: "",
          shop_sub_cate: "",
          shop_goods_price: "",
          shop_goods_num: "",
          shop_throw_money: "",
          shop_work_experience: "",
          shop_supply_source: "",
          shop_ship_mode: "",
          shop_country: "中国",
          shop_province: "",
          shop_city: "",
          shop_area: "",
          shop_province_code: "",
          shop_city_code: "",
          shop_area_code: "",
          shop_address: "",
          shop_video: [],
          shop_video_data: [],
          shop_other_video: [],
          shop_other_video_data: [],
          is_brand: "",
          shop_video_cover: [],
          shop_other_video_cover: [],
        },
      },
      rules1: {
        realname: {
          required: true,
          message: "必填真实姓名",
          trigger: "blur",
        },
        number: {
          required: true,
          message: "必填身份证号",
          trigger: "blur",
        },
        id_card_url_back: {
          required: true,
          message: "必传身份（背面照片）",
          trigger: "blur",
        },
        id_card_url_front: {
          required: true,
          message: "必传身份（正面照片）",
          trigger: "blur",
        },
      },
      rules2: {
        company_email: {
          required: true,
          message: "必填公司邮箱",
          trigger: "blur",
        },
        contact_mobile: {
          required: true,
          message: "必填联系人手机号",
          trigger: "blur",
        },
        contact_name: {
          required: true,
          message: "必传联系人姓名",
          trigger: "blur",
        },
        contact_position: {
          required: true,
          message: "必选联系人职位",
          trigger: "blur",
        },
        manage_type: {
          required: true,
          message: "请选择过往经验",
          trigger: "blur",
        },
      },
      rules3: {
        company_license: {
          required: true,
          message: "必填",
          trigger: "blur",
        },
        company_name: {
          required: true,
          message: "必填",
          trigger: "blur",
        },
        company_credit_code: {
          required: true,
          message: "必填",
          trigger: "blur",
        },
        company_income: {
          required: true,
          message: "必填",
          trigger: "blur",
        },
        company_total: {
          required: true,
          message: "必填",
          trigger: "blur",
        },
        company_is_factory: {
          required: true,
          message: "必填",
          trigger: "blur",
        },

        company_province: {
          required: true,
          message: "必填",
          trigger: "blur",
        },
        company_city: {
          required: true,
          message: "必填",
          trigger: "blur",
        },
        company_area: {
          required: true,
          message: "必填",
          trigger: "blur",
        },
        company_address: {
          required: true,
          message: "必填",
          trigger: "blur",
        },
      },
      rules4: {
        shop_main_cate: {
          required: true,
          message: "必填",
          trigger: "change",
        },
        shop_sub_cate: {
          required: true,
          message: "必填",
          trigger: "change",
        },
        shop_goods_price: {
          required: true,
          message: "必填",
          trigger: "change",
        },
        shop_goods_num: {
          required: true,
          message: "必填",
          trigger: "change",
        },
        shop_throw_money: {
          required: true,
          message: "必填",
          trigger: "change",
        },
        shop_work_experience: {
          required: true,
          message: "必填",
          trigger: "change",
        },
        shop_supply_source: {
          required: true,
          message: "必填",
          trigger: "change",
        },
        shop_ship_mode: {
          required: true,
          message: "必填",
          trigger: "change",
        },
        shop_country: {
          required: true,
          message: "必填",
          trigger: "change",
        },
        shop_province: {
          required: true,
          message: "必填",
          trigger: "change",
        },
        shop_city: {
          required: true,
          message: "必填",
          trigger: "change",
        },
        shop_area: {
          required: true,
          message: "必填",
          trigger: "change",
        },
        // shop_province_code: {
        //   required: true,
        //   message: "必填",
        //   trigger: "blur",
        // },
        // shop_city_code: {
        //   required: true,
        //   message: "必填",
        //   trigger: "blur",
        // },
        // shop_area_code: {
        //   required: true,
        //   message: "必填",
        //   trigger: "blur",
        // },
        shop_address: {
          required: true,
          message: "必填",
          trigger: "blur",
        },
        // shop_video: {
        //   required: true,
        //   message: "必填",
        //   trigger: "blur",
        // },
        // shop_other_video: {
        //   required: true,
        //   message: "必填",
        //   trigger: "blur",
        // },
        is_brand: {
          required: true,
          message: "必填",
          trigger: "change",
        },
      },
      options: {
        category: "",
      },
      imageUrl: "",
    };
  },
  created() {
    let inIndex = localStorage.ININDEX;
    if (inIndex) {
      // 获取已提交的信息
      inList().then((res) => {
        if (res.code == 1) {
          let infoData = res.data.list[inIndex];
          // 法人
          this.form.legal.realname = infoData.name;
          this.form.legal.number = infoData.number;
          // 法人身份证正面
          if (infoData.id_card_url_front != "") {
            this.form.legal.id_card_url_front.push(infoData.id_card_url_front);
            this.form.legal.id_card_front.push(infoData.attachment_id);
          }
          // 法人身份证反面
          if (infoData.id_card_url_back != "") {
            this.form.legal.id_card_url_back.push(infoData.id_card_url_back);
            this.form.legal.id_card_back.push(infoData.attachment_id2);
          }

          // 基本信息
          this.form.basic.company_email = infoData.company_email;
          this.form.basic.contact_mobile = infoData.contact_mobile;
          this.form.basic.sms_code = "888888";
          this.form.basic.contact_name = infoData.contact_name;
          this.form.basic.contact_position = infoData.contact_position;
          this.form.basic.manage_type = String(infoData.manage_type);
          // this.form.company = infoData.company_info?infoData.company_info:[];
          if (infoData.company_info) {
            this.form.company = infoData.company_info;
          }
          if (infoData.company_info) {
            this.form.company.company_income = String(
              infoData.company_info.company_income
            );
            this.form.company.company_total = String(
              infoData.company_info.company_total
            );

            Object.keys(this.areaList.city_list).forEach((item) => {
              let cityId = item.slice(0, 2);
              if (this.form.company.company_city_code.slice(0, 2) == cityId) {
                this.city_list[item] = this.areaList.city_list[item];
              }
            });
            Object.keys(this.areaList.county_list).forEach((item) => {
              let cityId = item.slice(0, 4);
              if (this.form.company.company_area_code.slice(0, 4) == cityId) {
                this.county_list[item] = this.areaList.county_list[item];
              }
            });

            let arr = [];
            let arr1 = [];
            // 营业执照
            if (infoData.company_info.company_license != "") {
              arr.push(infoData.company_info.company_license);
              arr1.push(infoData.company_info.attachment_id);
            }
            this.form.company.company_license = arr1;
            this.form.company.company_licenseIMG = arr;
          }

          if (infoData.shop_info) {
            this.form.shop = infoData.shop_info;
            Object.keys(this.areaList.city_list).forEach((item) => {
              let cityId = item.slice(0, 2);
              if (this.form.shop.shop_city_code.slice(0, 2) == cityId) {
                this.city_lists[item] = this.areaList.city_list[item];
              }
            });
            Object.keys(this.areaList.county_list).forEach((item) => {
              let cityId = item.slice(0, 4);
              if (this.form.shop.shop_area_code.slice(0, 4) == cityId) {
                this.county_lists[item] = this.areaList.county_list[item];
              }
            });
            // 视频
            let arr1 = [];
            let arr5 = [];
            if (infoData.shop_info.shop_video) {
              arr1.push(infoData.shop_info.back_video_id);
              arr5.push(infoData.shop_info.shop_video);
            }
            this.form.shop.shop_video = arr1;
            this.form.shop.shop_video_data = arr5;
            let arr3 = [];
            if (infoData.shop_info.shop_video_cover) {
              arr3.push(infoData.shop_info.shop_video_cover);
            }

            this.form.shop.shop_video_cover = arr3;

            let arr2 = [];
            let arr6 = [];
            if (infoData.shop_info.shop_other_video) {
              arr2.push(infoData.shop_info.other_video_id);
              arr6.push(infoData.shop_info.shop_other_video);
            }
            this.form.shop.shop_other_video = arr2;
            this.form.shop.shop_other_video_data = arr6;
            let arr4 = [];
            if (infoData.shop_info.shop_other_video_cover) {
              arr4.push(infoData.shop_info.shop_other_video_cover);
            }
            this.form.shop.shop_other_video_cover = arr4;
          }
        }
      });
    }
    // 获取入驻基本配置
    configList().then((res) => {
      if (res.code == 1) {
        this.options = res.data;
        // 获取主要品类
        getMainCategory().then((res) => {
          if (res.code == 1) {
            this.options.category = res.data;
          }
        });
      }
    });
  },
  mounted() {
    this.gundong();
  },
  destroyed() {
    window.onscroll = function () {};
  },
  methods: {
    shangchuan() {},
    toNEXT(type) {
      this.sm = type == 0 ? false : true;
      let img1 = this.$refs.tp1;
      let img2 = this.$refs.tp2;
      let img3 = this.$refs.tp3;
      let video1 = this.$refs.tp4;
      let video2 = this.$refs.tp5;
      switch (this.neet) {
        case 1:
          this.$refs.form1.validate((valid) => {
            if (valid) {
              // 法人信息
              legalAuth({
                // id_card_front: img1.imageUrl[0],
                // id_card_back: img2.imageUrl[0],
                id_card_front: img1.ids[0],
                id_card_back: img2.ids[0],
                realname: this.form.legal.realname,
                number: this.form.legal.number,
              }).then((res) => {
                if (res.code == 1) {
                  this.neet = 2;
                  window.scrollTo(0, 0);
                }
              });
            } else {
              return false;
            }
          });

          break;
        case 2:
          this.$refs.form2.validate((valid) => {
            if (valid || type == 0) {
              baseInfo({
                name: this.form.basic.contact_name,
                mobile: this.form.basic.contact_mobile,
                position: this.form.basic.contact_position,
                email: this.form.basic.company_email,
                experience: this.form.basic.manage_type,
                code: this.form.basic.sms_code,
                type: type,
              }).then((res) => {
                if (res.code == 1) {
                  if (type == 1) {
                    this.neet = 3;
                    window.scrollTo(0, 0);
                  } else {
                    this.$message.success("保存草稿成功");
                  }
                }
              });
            } else {
              if (type == 1) {
                return false;
              }
            }
          });

          break;
        case 3:
          this.$refs.form3.validate((valid) => {
            if (valid || type == 0) {
              companyInfo({
                ...this.form.company,
                company_license: img3.ids[0] ? img3.ids[0] : "",
                type: type,
              }).then((res) => {
                if (res.code == 1) {
                  if (type == 1) {
                    this.neet = 4;
                    window.scrollTo(0, 0);
                  } else {
                    this.$message.success("保存草稿成功");
                  }
                }
              });
            } else {
              if (type == 1) {
                return false;
              }
            }
          });

          break;
        case 4:
          this.$refs.form4.validate((valid) => {
            if (valid || type == 0) {
              shopInfo({
                ...this.form.shop,
                shop_video_cover: video1.imageUrl[0] ? video1.imageUrl[0] : "",
                shop_other_video_cover: video2.imageUrl[0]
                  ? video2.imageUrl[0]
                  : "",
                shop_video: video1.ids[0] ? video1.ids[0] : "",
                shop_other_video: video2.ids[0] ? video2.ids[0] : "",
                type: type,
              }).then((res) => {
                if (res.code == 1) {
                  if (type == 1) {
                    this.neet = 5;
                    window.scrollTo(0, 0);
                  } else {
                    this.$message.success("保存草稿成功");
                  }
                }
              });
            } else {
              if (type == 1) {
                return false;
              }
            }
          });

          break;
        default:
          break;
      }
      // window.scrollTo(0, 0);
    },

    toPrev() {
      switch (this.neet) {
        case 2:
          this.$refs.form2.clearValidate();
          this.neet = 1;
          break;
        case 3:
          this.$refs.form3.clearValidate();
          this.neet = 2;
          break;
        case 4:
          this.$refs.form4.clearValidate();
          this.neet = 3;
          break;
        default:
          break;
      }
      window.scrollTo(0, 0);
    },
    toPush(type) {
      switch (type) {
        case 1:
          this.$router.replace({
            path: "open",
            replace: true,
          });
          break;
        case 2:
          this.$router.back(-1);
          break;

        default:
          break;
      }
    },
    toJump() {
      this.$confirm(
        "必须完成法人实名认证才能提交申请，您可暂时跳过，并在最后提交申请前完成认证。",
        "提示",
        {
          confirmButtonText: "跳过",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$refs.form1.clearValidate();
          this.neet = 2;
        })
        .catch(() => {});
    },
    open() {
      this.dialogTableVisible = true;
      this.getCode();
    },
    getCode() {
      this.codeType = true;
      let _this = this;
      getSmsCode({
        mobile: this.form.basic.contact_mobile,
      }).then((res) => {
        if (res.code == 1) {
          _this.$message.success(res.message);
          setTimeout(function () {
            _this.form.basic.sms_code = res.data.code;
          }, 1500);

          this.codeTime = setInterval(function () {
            _this.codeNum--;
            _this.codeText = "重新发送(" + _this.codeNum + "s)";
            if (_this.codeNum == 0) {
              clearInterval(_this.codeTime);
              _this.codeNum = 60;
              _this.codeText = "重新获取";
              _this.codeType = false;
            }
          }, 1000);
        } else {
          _this.codeType = false;
        }
      });
    },
    close() {
      this.dialogTableVisible = false;
      clearInterval(this.codeTime);
      this.codeType = false;
      this.codeText = "重新获取";
      this.codeNum = 60;
    },
    selectedSwitch(id) {
      this.city_list = {};
      this.form.company.company_city_code = "";
      this.county_list = {};
      this.form.company.company_area_code = "";
      let cid = id.slice(0, 2);
      Object.keys(this.areaList.province_list).forEach((item) => {
        if (id == item) {
          this.form.company.company_province =
            this.areaList.province_list[item];
        }
      });
      Object.keys(this.areaList.city_list).forEach((item) => {
        let cityId = item.slice(0, 2);
        if (cid == cityId) {
          this.city_list[item] = this.areaList.city_list[item];
        }
      });
    },
    selectedSwitch2(id) {
      this.county_list = {};
      this.form.company.company_area_code = "";
      let cid = id.slice(0, 4);
      Object.keys(this.city_list).forEach((item) => {
        if (id == item) {
          this.form.company.company_city = this.city_list[item];
        }
      });
      Object.keys(this.areaList.county_list).forEach((item) => {
        let cityId = item.slice(0, 4);
        if (cid == cityId) {
          this.county_list[item] = this.areaList.county_list[item];
        }
      });
    },
    selectedSwitch3(id) {
      Object.keys(this.county_list).forEach((item) => {
        if (id == item) {
          this.form.company.company_area = this.county_list[item];
        }
      });
    },
    selectedSwitch4(id) {
      this.city_lists = {};
      this.form.shop.shop_city_code = "";
      this.county_lists = {};
      this.form.shop.shop_area_code = "";
      let cid = id.slice(0, 2);
      Object.keys(this.areaList.province_list).forEach((item) => {
        if (id == item) {
          this.form.shop.shop_province = this.areaList.province_list[item];
        }
      });
      Object.keys(this.areaList.city_list).forEach((item) => {
        let cityId = item.slice(0, 2);
        if (cid == cityId) {
          this.city_lists[item] = this.areaList.city_list[item];
        }
      });
    },
    selectedSwitch5(id) {
      this.county_lists = {};
      this.form.shop.shop_area_code = "";
      let cid = id.slice(0, 4);
      Object.keys(this.city_lists).forEach((item) => {
        if (id == item) {
          this.form.shop.shop_city = this.city_lists[item];
        }
      });
      Object.keys(this.areaList.county_list).forEach((item) => {
        let cityId = item.slice(0, 4);
        if (cid == cityId) {
          this.county_lists[item] = this.areaList.county_list[item];
        }
      });
    },
    selectedSwitch6(id) {
      Object.keys(this.county_lists).forEach((item) => {
        if (id == item) {
          this.form.shop.shop_area = this.county_lists[item];
        }
      });
    },
    gundong() {
      let that = this;
      this.$nextTick(() => {
        // this.initScroll()
        window.onscroll = function () {
          //变量scrollTop是滚动条滚动时，距离顶部的距离
          var scrollTop =
            document.documentElement.scrollTop || document.body.scrollTop;
          //变量windowHeight是可视区的高度
          var windowHeight =
            document.documentElement.clientHeight || document.body.clientHeight;
          // 元素离顶部的距离
          var boxTop = that.$refs.fdbz.offsetTop;
          if (scrollTop + windowHeight <= boxTop + 100) {
            that.scrollType = true;
          } else {
            that.scrollType = false;
          }
        };
      });
    },
  },
};
</script>
<style lang="less" scoped>
.inProcess {
  position: relative;
  background: #f2f5fa;
  // height: 1000px;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 160px;
    background: url(../../../assets/img/seller/applay-bg.jpg) no-repeat;
    background-position: 50%;
    background-size: cover;
    z-index: 1;
  }
  .stpes {
    width: 800px;
    margin: 0 auto;
    display: flex;
    position: relative;
    z-index: 2;
    .shopee-step {
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .shopee-step__head {
        width: 32px;
        height: 32px;
        font-size: 16px;
        font-weight: 400;
        background: #ffffff4d;
        line-height: 32px;
        text-align: center;
        border-radius: 50%;
        color: #fff;
      }
      .shopee-step__test {
        font-size: 14px;
        margin-top: 10px;
        color: #ffffffcc;
      }
      .shopee-step__tail {
        width: 120px;
        margin-top: 16px;
        height: 0;
        border-bottom: 1px dashed hsla(0, 0%, 100%, 0.5);
        background: none;
        position: absolute;
        margin-left: 100%;
      }
    }
    .active {
      .shopee-step__head {
        width: 32px;
        height: 32px;
        font-size: 16px;
        font-weight: 400;
        background: #fff;
        line-height: 32px;
        text-align: center;
        border-radius: 50%;
        color: #ee4d2d;
      }
      .shopee-step__test {
        font-size: 14px;
        margin-top: 10px;
        color: #fff;
      }
    }
  }
  .content {
    box-sizing: border-box;
    width: 1200px;
    margin: 32px auto 0;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgb(74 74 78 / 16%);
    border-radius: 2px;
    padding: 80px 380px 80px 220px;
    position: relative;
    z-index: 2;

    p {
      margin: 0;
      font-size: 12px;
      color: #999;
      line-height: 20px;
      margin-top: 4px;
    }
  }
  .success {
    width: 1200px;
    position: relative;
    z-index: 2;
    background: #fff;
    margin: 32px auto 0;
    padding: 80px 360px 80px 360px;
    line-height: 30px;
    font-size: 14px;
    button {
      width: 200px;
    }
  }
  .content-footer {
    padding: 12px 0;
    margin: 0 24px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
  }
  .content-footer2 {
    padding: 12px 0;
    margin: 0 24px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 2;
    width: 1200px;
    bottom: 0;
    background: #fff;
    padding: 12px 20px;
    left: 0;
    right: 0;
    margin: auto;
  }
}
.china {
  width: 55px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  background: #fafafa;
  border: 1px solid #e5e5e5;
  color: #b7b7b7;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.china:hover {
  cursor: not-allowed;
}
</style>