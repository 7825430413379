<template>
  <div class="BreadCrumbs" style="padding-top: 12px">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        replace
        v-for="(item, index) in route.matched"
        :key="index"
        :to="item.redirect"
        >{{ item.meta.name }}</el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>
</template>
<script>
export default {
  name: "BreadCrumbs",
  data() {
    return {
      route: this.$route,
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" >
.BreadCrumbs {
      position: relative;
    z-index: 1;
  .el-breadcrumb__inner a,
  .el-breadcrumb__inner.is-link {
    font-weight: 700;
    text-decoration: none;
    transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: hsla(0, 0%, 100%, 0.8);
  }
  .el-breadcrumb__inner a:hover,
  .el-breadcrumb__inner.is-link:hover {
    color: #fff;
    cursor: pointer;
  }
  .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner a,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    font-weight: 400;
    color: #fff;
    cursor: text;
  }
  .el-breadcrumb__separator {
    margin: 0 9px;
    font-weight: 700;
    color: hsla(0, 0%, 100%, 0.8);
  }
}
</style>